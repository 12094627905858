// import React, { useContext } from "react";
// import { useNavigate } from "react-router";
// // import "../comp/Subscription.css";
// import Context from "../../Context/Context";
// import HappyprancerPaypalMonthly from "../Subscription/HappyprancerPaypalMonthly";
// import HappyprancerRazorpayMonthly from "../Subscription/HappyprancerRazorpayMonthly";
// import Image from "../../Utils/images/raw/board.jpg";

// export default function Subscription() {
//   const Ctx = useContext(Context);
//   const UserCtx = useContext(Context).userData;

//   const Navigate = useNavigate();
//   console.log(Ctx.productList)

//   return (
//     <>
//       <div
//   className="Back text-[1.5rem] flex flex-col items-center h-auto max980:h-[auto] justify-center gap-[5rem] pb-20"
//   style={{
//     backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Image})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     backgroundOpacity: 0.9,
//     position: "relative",
//     isolation: "isolate",
//     borderBottom: "0.5rem solid #2C73EB",
//   }}
// >
//         <div className="text-center mt-20 RussoOne ">
//           <h1 style={{ color: "white"}}>Monthly Membership Subscription </h1>
//           <h3 className="text-[1rem]"style={{ color: "white"}}>see what are the pricing in details</h3>
//         </div>
//         <ul className="flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-28 ">
//           {Ctx.productList.map((item) => {
//             return (
//               <li key={item.productId} className="bg-white w-[22rem] h-[29rem] p-10 rounded-[2rem] z-0  flex flex-col items-center gap-8 shadowSubscribe   max450:w-[90vw] max450:gap-4 max450:text-[1rem] max450:min-h-[28rem] max450:h-auto max450:p-12 border-[#2C73EB]">
//                 <p className="text-[1.6rem]">{item.heading}</p>
//                 <ul className=" text-[1rem] h-auto pl-0 flex flex-col">
//                   {item.provides.map((i) => {
//                     return (
//                       <li >
//                         <p>{i}</p>
//                       </li>
//                     );
//                   })}
//                 </ul>
//                 <h1 className="text-left w-[100%]">
//                   {(item.currency === "INR" ? "₹ " : "$ ") +
//                     parseInt(item.amount) / 100 +
//                     "/" +
//                     item.durationText}
//                 </h1>
//                 {Ctx.isAuth ? (
//                   <div>
//                     {UserCtx.status === "Active" ? (
//                       <p className="text-[1rem] w-[15rem] px-12 max450:px-0 py-2 rounded-2xl text-[#2C73EB] bg-white border-[#2C73EB] border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]">
//                         Already Subscribed
//                       </p>
//                     ) : (
//                       <>
//                         {item.currency === "INR"
//                           ? item.durationText === "Month" && (
//                             <HappyprancerRazorpayMonthly />
//                           )
//                           : item.durationText === "Month" && (
//                             <HappyprancerPaypalMonthly />
//                           )}
//                       </>
//                     )}
//                   </div>
//                 ) : (
//                   <button
//                     onClick={() => {
//                       Navigate("/signup");
//                     }}
//                     className="w-[15rem] bg-[#2C73EB] text-white px-12 py-2 rounded-2xl hover:text-[#2C73EB] hover:bg-white hover:border-[#2C73EB] hover:border-[0.3rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]"
//                   >
//                     Sign Up
//                   </button>
//                 )}
//               </li>
//             );
//           })}
//         </ul>

//       </div>
//     </>
//   );
// }
import React, { useContext } from "react";
import "../comp/Subscription.css";
import Context from "../../Context/Context";
import Product from "../Subscription/Product";

export default function Subscription() {
const Ctx = useContext(Context);

return (
<>
<section className="Back  text-[1.5rem]  flex  flex-col items-center h-[90rem] max1414:h-[auto] justify-center gap-[5rem] pb-20 ">
<div className="text-center text-[white] text-[3.4rem] max500:text-[2rem] mt-20 font-bebas-neue ">
<h1 style={{ textTransform: 'uppercase', fontFamily: 'Russo One', fontWeight: '500' }}>check out our exclusive offers!</h1> {/*    Top header     */}
<h3 className="text-[1.5rem] max500:text-[1rem]" style={{ textTransform: 'uppercase', fontFamily: 'Russo One', fontWeight: '500'}}>
see what are the pricing in details
</h3>
</div>
<ul className="flex flex-wrap justify-center items-center w-[80vw] gap-28 max800:flex-col">
{Ctx.productList.map((product) => {
return Product(product);
})}
</ul>
</section>
</>
);
}


