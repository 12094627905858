import React, { useContext } from "react";
import Context from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import RtigerRazorpay from "./RtigerRazorpay";

const Product = (product) => {
const Ctx = useContext(Context);
const UserCtx = useContext(Context).userData;

const Navigate = useNavigate();

return (
<li className="bg-white w-[20rem]   h-[28rem] max450:h-[27rem] rounded-[2rem]  flex flex-col justify-center items-center gap-4 shadowSubscribe   max450:w-[70vw] max450:gap-[1rem] max450:text-[1rem]   max450:p-12  border-[0.1rem]">
<p className="font-gidugu leading-[1.5rem] h-[1rem] max500:text-[1.4rem] text-[2.2rem] mb-[0.8rem] ">
{product.heading}
</p>
<div className=" flex w-full flex-col gap-5 items-center text-left">
<ul className="list-disc text-[1.2rem] max500:text-[1rem] pl-10 text-left">
{product.provides.map((provide) => {
return (
<li className="text-left">
<p className="font-poppins text-left">{provide}</p>
</li>
);
})}
</ul>
</div>
<h1 className="text-center text-[1rem] font-russo w-[100%]">
{(product.currency === "INR" ? "₹" : "$") +
product.amount / 100 +
" / " +
product.subscriptionType}
</h1>
{Ctx.isAuth ? (
<div>
{UserCtx.status === "Active" ? (
<p className="text-[1rem] w-[15rem] px-12 py-2 rounded-2xl text-[#2C73EB] bg-white border-[#2C73EB] border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw] max450:text-[0.8rem]">
Already Subscribed
</p>
) : (
<>
{product.currency === "INR" ? (
<RtigerRazorpay productId={product.productId} />
) : (
<div></div>
)}
</>
)}
</div>
) : (
<button
onClick={() => {
Navigate("/signup");
}}
className="w-[15rem] bg-[#2C73EB] text-black px-12 py-2 font-russo rounded- mt-[2rem] hover:text-[#2C73EB] hover:bg-white hover:border-[#2C73EB] hover:border-[0.3rem] h-[3rem] flex justify-center items-center  max450:w-[40vw]"
>
Sign Up
</button>
)}
</li>
);
};

export default Product;

