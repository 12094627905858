const data = {
rows: [
{
title: "    1. How to Join?",
content: `Please use subscribe option to subscribe to monthly or yearly subscription. This will take you to PayPal for payment. Once payment is made you will be asked to authenticate your email address or phone number. Once that is done you will be able to add a password.`,
},
{
title: "    2. How to join online workout?",
content: `The Zoom link is provided for each online class on the bworkzlive.com. You need to login to access the links. You do not need any password to access the Zoom from the link.`,
},
{
title: "    3. What is R-Tigers?",
content: `R-Tigers offers a variety of classes that cater to different fitness levels and preferences. For those interested in martial arts, we have Taekwondo classes taught by experienced instructors that will help you develop your strength, speed, and agility. If you're looking to improve your flexibility, balance, and mental focus, our yoga classes are a great option. Our aerobics classes provide a fun and energetic workout that will get your heart rate up and help you burn calories. For those who love to dance, we offer Zumba classes that incorporate Latin and international music, as well as other dance classes that will help you improve your rhythm and coordination. Come try out our classes and take the first step towards a healthier, happier you!`,
},

],
};

export default data;
