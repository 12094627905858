import React from "react";
import video from "../../Utils/Happy/images/backgroundvideo.mp4";

const Header = () => {

return (
<div className=" self-stretch overflow-hidden h-[30rem] flex flex-col items-start justify-start text-left text-[2rem] text-white font-poppins">
<div className="absolute z-10 flex flex-col items-left h-[inherit] max800:px-[4rem] max375:px-[2rem] px-[10rem] py-[10rem] justify-end w-screen">
<h3 className="m-0 relative text-[inherit] max375:text-[1.2rem] max375:leading-[1rem] max600:text-[1.5rem] leading-[2.4rem] font-regular font-inherit  text-left">The Sign of prevail</h3>
<h1 className="m-0 relative text-[2.4rem] max800:text-[1.8rem]    max375:text-[1.2rem]  max375:leading-[1.6rem] max600:text-[2rem] max600:leading-[3rem] leading-[2.94rem] font-bold font-inherit text-[#2C73EB]">
Learn from the Best - Dance Professionals
</h1>
<h5 className="m-0 relative  h-[1.5rem] shrink-0 text-[1rem] font-inherit">
<div className="absolute top-[0rem] left-[0rem] leading-[1.5rem] max375:text-[1rem]">
Empower Your Gym with Intelligent Websites
</div>
</h5>
</div>


<div className="z-5 ">
<video
autoPlay
loop
muted
playsInline={true}
controls={false}
className="object-cover object-top h-[38rem] w-screen   max-w-screen"
>
<source src={video} type="video/mp4" />
</video>
</div>
</div>
);
};

export default Header;

