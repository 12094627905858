// import React from "react";
// import NavBar from "../Components/NavBar";
// import "./about_us.css";
// import Footer from "../Components/Home/Footer";

// const AboutUs = () => {
//   return (
//     <>
//       <div className="flex flex-col items-center ">
//         <NavBar />
//         <div className=" text-black flex flex-col items-center w-100 mt-[2rem] p-0 overflow-x-hidden w-[90vw] max-w-[80rem]">
//           <h1 className="sans-sarif text-[4rem] text-center mt-9">About Us</h1>
//           <p className="mt-8">
//             Welcome to rtiger.happyprancer.com, the ultimate destination for dance fitness enthusiasts. Our website offers a wide range of online dance fitness resources, including Zumba classes that use mostly Latin music and BWORKZ classes that use Bollywood music for dance fitness. Our founder, PK, knows firsthand the struggle of making time for fitness amidst a busy work schedule. Despite joining some of the best fitness clubs in town, he found it challenging to incorporate exercise into his daily routine until he discovered the joy of Zumba dance. 
//           </p>
//           <p className="mt-5">
//              Zumba proved to be an enjoyable and engaging fitness routine for PK, which not only helped him stay fit but also provided an opportunity to make friends and have fun with dance and music. This positive experience inspired PK to bring his passion for dance fitness to everyone he knew in the US via Zoom when the pandemic hit and all fitness clubs were forced to close. 
//           </p>
//           <p className="mt-5">
//            At rtiger.happyprancer.com, we believe that fitness can be fun and enjoyable, and we are committed to helping people lead healthier, happier lives, especially during these challenging times. Our website offers a holistic approach to fitness, incorporating mindfulness and self-care practices into our resources. We understand that fitness is not just about physical health, but also about mental and emotional well-being. 
//           </p>
//           <p className="mt-6 mb-20">
//           Our team of passionate individuals is dedicated to spreading happiness and promoting a positive outlook on life. We believe that everyone deserves to feel good about themselves and that everyone has the potential to lead a happy, healthy life. Join us on our journey to spread positivity and inspire people to live their best lives with the fusion of Zumba and BWORKZ dance fitness routines 
//           </p>
//           {/* <div className="my-8 text-[1.2rem] flex flex-col gap-6 w-[90vw] max-w-[60rem] border-[0]">
//             <h1>WATCH MORE ON OUR YOUTUBE</h1>
//             <div>
//               <iframe
//                 // width="844"
//                 // height="515"
//                 src="https://www.youtube.com/embed/pPzIbKB2GNQ"
//                 className="w-[80vw] h-[45vw]"
//                 title="YouTube video player"
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                 allowFullScreen
//               ></iframe>
//             </div>
//           </div> */}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default AboutUs;
import NavBar from "../Components/NavBar";
import "./about_us.css";
import Footer from '../Components/Home/Footer'
import Gallery from './Gallary/Gallery';
import Pic1 from "../Utils/images/Gallary/Gal-1.JPG";
import Pic2 from "../Utils/images/Gallary/Gal-2.JPG";
import Pic3 from "../Utils/images/Gallary/Gal-3.JPG";
import Pic4 from "../Utils/images/Gallary/Gal-4.JPG";
import Pic5 from "../Utils/images/Gallary/Gal-5.JPG";
import Pic6 from "../Utils/images/Gallary/Gal-6.JPG";



const AboutUs = () => {
// Array of gallery images
const galleryImages = [
{
"img": Pic1
},
{
"img": Pic2
},
{
"img": Pic3
},
{
"img": Pic4
},
{
"img": Pic5
},
{
"img": Pic6
}
];

// Array of paragraphs for the about section
const aboutParagraph = [
"Welcome to our fitness gym! We are excited to offer a wide range of classes that cater to different fitness levels and preferences. Our team of experienced and certified instructors is dedicated to helping you achieve your fitness goals, whether you're looking to improve your strength, flexibility, coordination, or overall well-being.",
"Our Taekwondo classes are led by a Master Instructor with over 20 years of experience. Whether you're a beginner or an advanced student, you'll benefit from the discipline, focus, and self-defense skills that Taekwondo offers.",
"Our aerobics classes provide a fun and energetic workout that will get your heart rate up and help you burn calories. With a variety of routines and music genres, our aerobics classes are suitable for all fitness levels and will leave you feeling energized and empowered.",
"For those who love to dance, we offer a range of classes that incorporate different styles and rhythms. Our Zumba classes are especially popular, with their Latin and international music and easy-to-follow choreography. We also offer other dance classes that will help you improve your coordination, balance, and grace.",
"In addition to our classes, we also offer personalized fitness plans and nutrition coaching to help you achieve your goals and maintain a healthy lifestyle. Our gym is equipped with state-of-the-art equipment, including cardio machines, weights, and functional training gear, and we offer private training sessions for those who prefer one-on-one coaching.",
"At our gym, we believe that fitness should be fun, challenging, and rewarding. We strive to create a supportive and inclusive community where everyone feels welcome and motivated to achieve their best. Come join us and experience the benefits of a healthy and active lifestyle!"
];

return (
<div>
<div className="flex flex-col items-center text-justify Background1 pb-[8rem] h-[100%] w-screen">
<NavBar />
<div className="text-[white] flex flex-col items-center w-100 h-100 mt-[2rem] p-0 w-[90vw] max-w-[80rem]">
<h1 className="text-[4rem] text-center max450:[1.2rem] font-bebas-neue">About Us</h1>
<h3 className="text-[2rem] max450:text-[1.8rem] font-poppins text-left">KNOW WHAT WE ARE</h3>
<div className="stoke">
{/* Render paragraphs from the aboutParagraph array */}
{aboutParagraph.map((paragraph, index) => (
<p className="mt-2 h-100 px-10" key={index}>{paragraph}</p>
))}
</div>

<div className="w-full h-full flex flex-col items-center justify-center">
<br />
<div>
<strong className="text-[1.6rem]">-- Checkout Our Gallery --</strong>
</div>
<br /><br />

<Gallery
galleryImages={galleryImages}
/>

          </div>
        </div>
        </div>
        <div className="w-full h-0.5 bg-blue-500"></div>
        <Footer />
    </div>
  );
};

export default AboutUs;

