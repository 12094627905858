// import React, { useContext } from "react";
// import { useNavigate } from "react-router";
// // import "../comp/Subscription.css";
// import Context from "../Context/Context";
// import HappyprancerPaypalMonthly from "../Components/Subscription/HappyprancerPaypalMonthly";
// import HappyprancerRazorpayMonthly from "../Components/Subscription/HappyprancerRazorpayMonthly";
// import NavBar from "../Components/NavBar";
// import Footer from "../Components/Home/Footer";
// import HappyprancerPaypalHybrid from "../Components/Subscription/HappyprancerPaypalHybrid";
// import Image1 from "../Utils/images/raw/board.jpg";

// export default function Subscription() {
//   const text = {
//     Heading: 'Monthly Membership Subscription',
//     sub_Heading: 'see what are the pricing in details'
//   };
//   const Ctx = useContext(Context);
//   const UserCtx = useContext(Context).userData;

//   const Navigate = useNavigate();


//   const paymentHandler = (item) => {
//     if (item.currency === "INR" && item.subscriptionType === "Monthly") {
//       return <HappyprancerRazorpayMonthly />;
//     }
//     if (item.currency === "USD" && item.subscriptionType === "Monthly") {
//       return <HappyprancerPaypalMonthly />;
//     }
//     if (item.currency === "USD" && item.subscriptionType === "Hybrid") {
//       return <HappyprancerPaypalHybrid />;
//     }
//   };

//   return (
//     <>
//       <NavBar />
//       <div
//   className="Back text-[1.5rem] flex flex-col items-center h-auto max980:h-[auto] justify-center gap-[5rem] pb-20"
//   style={{
//     backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Image1})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     backgroundOpacity: 0.9,
//     position: "relative",
//     isolation: "isolate",
//     borderBottom: "0.5rem solid #2C73EB",
//   }}
// >
//         <div className="text-center mt-20 RussoOne ">
//           <h1 style={{ color: "white"}}>{text.Heading}</h1>
//           <h3 className="text-[1rem]"style={{ color: "white"}}>{text.sub_Heading}</h3>
//         </div>
//         <ul className="flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-28 ">
//           {Ctx.productList.map((item) => {
//             return (
//               <li className="bg-white w-[24rem] py-16 px-8 rounded-[2rem] z-0  flex flex-col items-center gap-8 shadowSubscribe   max450:w-[90vw] max450:gap-4 max450:text-[1rem] max450:min-h-[28rem] max450:h-auto max450:p-12  border-[0.1rem]">
//                 <p className="text-[1.6rem]">{item.heading}</p>
//                 <ul className=" text-[1rem] h-auto pl-0 flex flex-col">
//                   {item.provides.map((i) => {
//                     return (
//                       <li >
//                         <p>{i}</p>
//                       </li>
//                     );
//                   })}
//                 </ul>
//                 <h1 className="text-left w-[100%]">
//                   {(item.currency === "INR" ? "₹ " : "$ ") +
//                     parseInt(item.amount) / 100 +
//                     "/" +
//                     item.durationText}
//                 </h1>
//                 {Ctx.isAuth ? (
//                   <div className=" ">
//                     {UserCtx.status === "Active" ? (
//                       <p className="text-[1rem] w-[15rem] px-12 max450:px-0 py-2 rounded-2xl bg-white border-[#2C73EB] border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[80vw]">
//                         Already Subscribed
//                       </p>
//                     ) : (
//                       <>{paymentHandler(item)} </>
//                     )}
//                   </div>
//                 ) : (
//                   <button
//                     onClick={() => {
//                       Navigate("/signup");
//                     }}
//                     className="w-[15rem] bg-[#2C73EB] text-white px-12 py-2 rounded-2xl hover:text-[#2C73EB] hover:bg-white hover:border-[#2C73EB] hover:border-[0.3rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]"
//                   >
//                     Sign Up
//                   </button>
//                 )}
//               </li>
//             );
//           })}
//         </ul>
//       </div>
//       <Footer />
//     </>
//   );
// }
import React, { useContext } from "react";
import Context from "../Context/Context";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Home/Footer";
import Product from "../Components/Subscription/Product";

export default function Subscription() {
const Ctx = useContext(Context);

return (
<>
<NavBar />
<div className="Back text-[1.5rem]  flex  flex-col items-center min-h-[50rem] max980:h-[auto] justify-center gap-[5rem] pb-20 bg-[#f5f5f5]">
<div className="text-center mt-20 RussoOne text-white">
<h1>Membership Subscription </h1>
<h3 className="text-[1rem]">see what are the pricing in details</h3>
</div>
<ul className="flex justify-center w-[90vw]  gap-28 flex-wrap">
{Ctx.productList.map((product) => {
return Product(product);
})}
</ul>
</div>
<Footer />
</>
);
}

