import { useState } from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faCircleChevronLeft,
//   faCircleChevronRight,
//   faCircleXmark
// } from '@fortawesome/free-solid-svg-icons'
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
// import cross from "../../Utils/images/raw/xmark-solid.svg"

import './gallery.css'

const WSPGallery = ({galleryImages}) => {

// State variables
const [slideNumber, setSlideNumber] = useState(0)
const [openModal, setOpenModal] = useState(false)

// Open Modal with selected image
const handleOpenModal = (index) => {
setSlideNumber(index)
setOpenModal(true)
}

// Close Modal
const handleCloseModal = () => {
setOpenModal(false)
}

// Previous Image
const prevSlide = () => {
slideNumber === 0
? setSlideNumber( galleryImages.length -1 )
: setSlideNumber( slideNumber - 1 )
}

// Next Image
const nextSlide = () => {
slideNumber + 1 === galleryImages.length
? setSlideNumber(0)
: setSlideNumber(slideNumber + 1)
}

return (
<div>

{/* Modal */}
{openModal &&
<div className='sliderWrap'>
{/* <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal}  />
<FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
<FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
Fullscreen image */}
<BsArrowLeftCircle
color="#ffff"
size={"2rem"}
className="btnPrev"
onClick={prevSlide}
/>
<BsArrowRightCircle
color="#ffff"
size={"2rem"}
className="btnNext"
onClick={nextSlide}
/>
<button
className='btnClose'
onClick={handleCloseModal}
style={{
fontSize: '24px',
fontWeight: 'bold',
verticalAlign: 'bottom'
}}
>
x
</button>


<div className='fullScreenImage'>
<img src={galleryImages[slideNumber].img} alt='' />
</div>
</div>
}

{/* Gallery */}
<div className='galleryWrap'>
{/* Render gallery images */}
{
galleryImages && galleryImages.map((slide, index) => {
return(
<div
className='single'
key={index}
onClick={ () => handleOpenModal(index) }
>
<img src={slide.img} alt='' />
</div>
)
})
}
</div>

</div>
)
}

export default WSPGallery

