import AshaPng from "../../../Utils/images/Testimonial/Asha.png";
import JajatiPng from "../../../Utils/images/Testimonial/Jajati.png";
import PriyankaPng from "../../../Utils/images/Testimonial/Priyanka.png";
import ShoophyPng from "../../../Utils/images/Testimonial/Soophy.png";
import RoyalinPng from "../../../Utils/images/Testimonial/Royalin.png";

const data = [
{ src: JajatiPng,
name: "Jajati Keshari Naik",
description:
"I joined RTigers gym five months ago, and my experience has been awesome. The training provided by Papu Sir and Ravindra Sir is ultimate, and I have lost almost 10 kg without following any specific diet plan. I feel fresh and active. I am grateful to RTigers for providing such great health advice and training in Bhawanipatna. Live young and live free! ",
},
{
src: AshaPng,
name: "Asha Maharana",
description:
"The program is incredible, with highly professional teaching that provides the best training I have ever had. I felt so comfortable, focused, and learned so many things. The combination of dance, fitness, and fun, along with inclusivity, was amazing! Thank you so much Ravindra Sir and Pappu Sir, you both are amazing. It has helped me a lot in losing weight, making my body more flexible, and relaxing my mind. I highly recommend RTiger's Fitness studio. ",
},
{
src: ShoophyPng,
name: "Soophy Sharma",
description:
"I had an amazing experience at this fitness centre, and it's one of the best I have ever joined. The best thing about it is that it comes with a full package of fitness activities and workouts, such as 💃Zumba, yoga, and step boards, etc. The instructors are friendly, supportive, and encouraging, and I love it❤️. Don't miss the opportunity and join us in Zumba and fitness workouts! 🤗😉 ",
},
{
src: PriyankaPng,
name: "Priyanka Priyadarshni Pattnayak",
description:
"A friendly environment, flexible and suitable timings, a purely hygienic place, the best trainers 🏋️‍♂️🏋️‍♀️😀, the latest songs played, and a refreshing experience for the mind and body. The trainers also maintain proper coordination and bonding between freshers, experienced individuals, and themselves, while ensuring full entertainment and equality among the trainers and students. ❤️ ",
},
{
src: RoyalinPng,
name: "Rojalin Nanda",
description:
"Amazing personal trainers and a completely clean gym. I would like to give 5 stars. I highly recommend it! ",
},
];

export default data;

