// import logo from "../../Utils/Happy/images/logo.png";
// import facebook from "../../Utils/Assests/FB.png";
// import instagram from "../../Utils/Assests/INSTA.png";
// import { Link, useNavigate } from "react-router-dom";
// import React, { useEffect } from "react";

// const Footer = (props) => {
//   const Navigate = useNavigate();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [props.initialContent]);


//   return (
//     <div>
//       <div className="bg-[#000] ">
//         <div className="flex flex-wrap justify-between max600:flex-col max600:justify-center p-12 gap-6 max1358:justify-center ">
//           <div className="mb-5">
//             <a href="/" className="transition duration-200 flex justify-center">
//               <img className="w-[15rem]" src={logo} alt="" />                                     {/* Logo  */}
//             </a>
//           </div>

//           <ul className=" flex gap-32 max950:gap-16 text-[#fff] flex-wrap max1050:justify-center ">           {/* Quick Links  */}
//             <li className="RussoOne flex flex-col gap-4 items-center text-cente ">
//               <h2 className="">Usefull Links</h2>
//               <hr className="w-[100%] text-[#fff] " />

//               <p
//                 className="cursor-pointer text-[#fff] text-decoration-none"
//                 onClick={() => {
//                   Navigate("/query");
//                 }}
//               >
//                 Contact Us
//               </p>
//               <a
//                 className="cursor-pointer text-[#fff] text-decoration-none"
//                 href="https://bworkzlive.com/"
//                 target={"_blank"}
//                 rel="noreferrer"
//               >
//                 BWorkz
//               </a>
//               <a
//                 className="cursor-pointer text-[#fff] text-decoration-none"
//                 href="https://Zumba.com/"
//                 target={"_blank"}
//                 rel="noreferrer"
//               >
//                 Zumba
//               </a>

//             </li>


//           </ul>
//         </div>

//         <div className=" h-16 bg-[#2C73EB] flex w-full items-center justify-start px-[2rem]">                                                                            {/* Social media Links  */}
//           <div className="flex  bg-[#000] justify-between  items-center w-[7rem]  rounded-2xl h-12 p-4">
//             <a
//               href="https://www.instagram.com/rtiger"
//               target={"_blank"}
//               rel="noreferrer"
//             >
//               <img
//                 src={instagram}
//                 alt=""
//                 className="hover:mr-2 hover:w-10 hover:h-10 w-8 h-8"
//               />
//             </a>
//             <a
//               href="https://www.instagram.com/rtiger"
//               target={"_blank"}
//               rel="noreferrer"
//             >
//               <img
//                 src={facebook}
//                 alt=""
//                 className="hover:mr-2 hover:w-10 hover:h-10 w-8 h-8"
//               />
//             </a>

//           </div>
//         </div>

//         <div className=" p-4 flex justify-center text-white gap-2 font-poppins max536:flex-col max536:text-center ">        {/*      Policy Links      */}
//           <Link to={"/privacypolicy"} className="cursor-pointer text-[#fff] text-decoration-none"> Privacy Policy</Link>
//           <div className="bg-[#2C73EB] w-1 border-[fff] rounded-md"></div>
//           <Link to={"/terms"} className="cursor-pointer text-[#fff] text-decoration-none"> Terms and Condition </Link>
//           <div className="bg-[#2C73EB] w-1 border-[fff] rounded-md"></div>
//           <Link to={"/refund"} className="cursor-pointer text-[#fff] text-decoration-none">  Cancellation/Refund Policy </Link>
//           <div className="bg-[#2C73EB] w-1 border-[fff] rounded-md"></div>
//           <h5 className="text-[1rem]"> All rights reserved. © 2023 rtiger.happyprancer.com</h5>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;


import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../Utils/Happy/images/Logo.svg";
import facebook from "../../Utils/Assests/FB.png";
import instagram from "../../Utils/Assests/INSTA.png";
import youtube from "../../Utils/Assests/YOU.png";


const socialMediaLinks = [
{
platform: "Instagram",
url: "https://www.instagram.com/rtigersfitnessstudio/",
icon: instagram
},
{
platform: "Facebook",
url: "https://www.facebook.com/rtigersfitnessstudio?mibextid=ZbWKwL",
icon: facebook
},
{
platform: "Youtube",
url: "https://www.youtube.com/@rtigersfitnessstudio",
icon: youtube
}
];

const affiliatedGyms = [
{
name: "HappyPrancer",
url: "https://happyprancer.com/"
},
{
name: "BWORKZ",
url: "https://bworkzlive.com/"
},
{
name: "AWSAIAPP",
url: "http://awsaiapp.com.s3-website-us-east-1.amazonaws.com/"
}
];

const usefulLinks = [
{
title: "Contact Us",
onClick: (Navigate) => Navigate("/query"),
style: { color: 'white', textDecoration: 'none' }
},
{
title: "Instructor",
onClick: (Navigate) => Navigate("/instructor"),
style: { color: 'white', textDecoration: 'none' }
},
{
title: "Youtube",
url: "https://www.youtube.com/@rtigersfitnessstudio",
style: { color: 'white', textDecoration: 'none' }
}
];

const Footer = () => {
const Navigate = useNavigate();

// Scroll to top on content change
useEffect(() => {
window.scrollTo(0, 0);
}, []);

return (
<div>
<div className="bg-black">
<div className="flex flex-wrap justify-between p-6 gap-4 max1008:justify-center">
<div className="mb-4">
<a href="/" className="transition duration-200">
<img src={logo} className="w-[8rem] max450:w-[10rem]" alt="" />
</a>
</div>

<ul className="flex gap-8 max950:gap-2 text-white flex-wrap max1050:justify-center text-left px-4">

<li className="RussoOne flex flex-col gap-2 items-center text-left">
<h2 className="text-base">Useful Links</h2>
{/* <div className="w-[100%] h-[0.1rem] text-white bar"></div> */}
<div className="w-full h-0.5" style={{ background: 'linear-gradient(90deg, #2C73EB 16.67%, #353534 90.74%)' }}></div>
{usefulLinks.map((link, index) => (
<React.Fragment key={index}>
{link.onClick ? (
<p
className="cursor-pointer text-sm hover:underline focus:outline-none"
onClick={() => link.onClick(Navigate)}
style={{ margin: '0' }}
>
{link.title}
</p>
) : (
<a
className="cursor-pointer text-sm"
href={link.url}
target="_blank"
rel="noreferrer"
style={link.style || {}}
>
{link.title}
</a>
)}
</React.Fragment>
))}
</li>


<li className="RussoOne flex flex-col gap-2 items-center text-center">
<h2 className="text-base">Affiliated Gyms</h2>
{/* <div className="w-[100%] h-[0.1rem] text-white bar"></div> */}
<div className="w-full h-0.5" style={{ background: 'linear-gradient(90deg, #2C73EB 16.67%, #353534 90.74%)' }}></div>
{affiliatedGyms.map((gym, index) => (
<a
key={index}
className="cursor-pointer text-sm hover:underline  focus:outline-none"
style={{ textDecoration: 'none',color: 'white' }}
href={gym.url}
target="_blank"
rel="noreferrer"
>
{gym.name}
</a>
))}
</li>
</ul>
</div>

<div className="py-[0.3rem] px-4 h-12 bg-[#2C73EB]">
{/* Social Contacts */}
<div className="flex bg-white justify-between items-center w-[15%] min-w-[6rem] max-w-[8rem] rounded-md h-10 p-2">
{socialMediaLinks.map((link, index) => (
<a
key={index}
href={link.url}
target="_blank"
rel="noreferrer"
>
<img
src={link.icon}
alt=""
className="hover:mr-1 hover:w-8 hover:h-8 w-7 h-7 focus:outline-none "
/>
</a>
))}
</div>
</div>

<div className="RussoOne p-2 flex justify-center text-white gap-1 text-xs">
  <div className="bg-[#2C73EB] w-0.5 h-4 border-white rounded-md"></div>
  <h5 style={{ fontSize: '1rem' }}>
    &copy; All Rights Reserved By R-Tigers
  </h5>
</div>
</div>
</div>
);
};

export default Footer;


