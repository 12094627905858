// import React from "react";

// import image1 from "../../Utils/images/section1-items/section-1-item-1.png";
// import image2 from "../../Utils/images/section1-items/section-1-item-2.png";
// import image3 from "../../Utils/images/section1-items/section-1-item-3.png";

// const Header3 = () => {
//   return (
//     <div className="flex justify-center flex-col items-center min-h-[50vh] gap-5 max850:h-auto py-20 ">
//       <h3 className="text-[#999999] font-semibold text-[1.2rem]">
//       A Fitness Movement
//       </h3>
//       <h4 className="text-[#151618] font-semibold text-[2.1rem]">
//       Why Rtigers
//       </h4>
//       <div>
//         <ul className="flex justify-center flex-wrap items-center gap-10">
//           <li className="flex flex-col items-center w-[20rem]">
//             <img
//               src={image1}
//               alt="image1"
//               className="w-[110px] h-[80px] object-contain"
//             />
//             <h5>Stay Home Stay Fit</h5>
//             <p className="text-center">
//             Free online classes via Google meet/Zoom for continous workout.
//             </p>
//           </li>
//           <li className="flex flex-col items-center w-[20rem] ">
//             <img
//               src={image2}
//               alt="image2"
//               className="w-[80px] h-[80px] object-contain"
//             />
//             <h5>Instructor Led Classes</h5>
//             <p className="text-center">
//             Online classes are conducted by Zumba Internationally Certified instructors.
//             </p>
//           </li>
//           <li className="flex flex-col items-center w-[20rem] ">
//             <img
//               src={image3}
//               alt="image3"
//               className="w-[100px] h-[80px] object-contain"
//             />
//             <h5>Personal & Group Exercise</h5>
//             <p className="text-center">
//             Personal (person-to-person trainer based) and Group exercise with friends
//             </p>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Header3;
import React from "react";
import VR from "../../Utils/images/raw/VR.png";
import "./Header3.css";


const Header2 = () => {
// Define an array of services with their titles and items
const services = [
{
title: "We Provide",
items: [
"Fun and engaging: Fitness classes often incorporate music and upbeat energy to make the workouts fun and engaging.",
"Professional guidance: Fitness classes are typically led by trained instructors who can help you maintain proper form and prevent injury.",
"Group atmosphere: Many fitness classes are designed to be done in a group setting, which can provide motivation and support.",
],
},
{
title: "Taekwondo",
items: [
"Sparring (Kyorugi): Online sessions can also cover Taekwondo sparring techniques, including strategies, techniques, and rules.",
"Self-defense techniques: Online sessions can teach self-defense techniques, such as escapes, joint locks, and throws.",
"Mental training: Online sessions can also incorporate mental training, such as visualization and breathing exercises, to help students develop focus and concentration.",
],
},
{
title: "Zumba (Aerobics)",
items: [
"Basic moves: Online sessions can teach the basic moves of Zumba, such as salsa, merengue, and reggaeton.",
"Choreography: Online sessions can cover choreographed routines that combine different dance styles and moves.",
"Community: Online sessions can include a community of fellow Zumba enthusiasts who can offer support and motivation.",
],
},
{
title: "Yoga",
items: [
"Yoga postures (asanas): Our Online sessions covers a variety of yoga postures, from basic to advanced, that help to build strength, flexibility, and balance.",
"Breathing techniques (pranayama): Online sessions can teach breathing techniques that help to calm the mind, reduce stress, and improve concentration.",
],
},
];

// const sectionStyle = {
//   backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 1.44), rgba(0, 0, 0, 0.54)), url(${image4})`,
// backgroundSize: "cover",
// backgroundPosition: "center",
// };
return (
<div className="New flex justify-between max600:h-[90rem] h-[52rem] text-white blurimg w-[auto] relative pt-[3.5rem] pb-20 pr-5 pl-5 max600:flex-col max600:mx-0 max600:items-start max600:m-0 max600:w-[90vw] overflow-hidden max800:gap-[2rem]">
<div className="p-10 flex flex-col max600:items-center justify-between bg-transparent border-y-[0.4rem] rounded-tl-lg rounded-bl-lg border-l-[0.4rem] border-[#2C73EB] w-[38vw] h-[45rem] max600:h-auto max600:border-0 max600:w-[100%] max600:gap-12">
{/* Render the first two services on the left side */}
{services.slice(0, 2).map((service, index) => (
<div className="w-[20rem] max800:w-[14rem] max600:w-[100%]" key={index}>
<h1 className="text-[2rem] max800:text-[1.5rem] font-russo max600:text-[1.6rem]"style={{ marginLeft: '28px' }}>{service.title}</h1>
<ul className="max800:text-[0.8rem] list-disc">
{service.items.map((item, itemIndex) => (
<li key={itemIndex}>{item}</li>
))}
</ul>
</div>
))}
</div>
<div className="Over p-10 flex flex-col max600:items-center max600:pt-0 max950:gap-[1.8rem] gap-[1.0rem] items-end bg-transparent border-y-[0.4rem] rounded-tr-lg rounded-br-lg border-r-[0.4rem] border-[#2C73EB] w-[38vw] h-[45rem] max600:h-auto max600:border-0 max600:w-[100%] max800:gap-[1.5rem]" style={{ marginBottom: '-25px' }}>
{/* Render the last two services on the right side */}
{services.slice(2).map((service, index) => (
<div className="w-[20rem] max800:w-[14rem] max600:w-[100%]" key={index}>
<h1 className="text-[2rem] max800:text-[1.5rem] max600:text-[1.6rem] font-russo max950:pl-[3rem] max600:pl-0 mb-2" style={{ marginLeft: '28px' }}>{service.title}</h1>
<ul className="max800:text-[0.8rem] list-disc max950:pl-[3rem] max600:pl-0 mt-2">
{service.items.map((item, itemIndex) => (
<li key={itemIndex}>{item}</li>
))}
</ul>
</div>
))}
</div>


<img src={VR} className="xs:block hidden absolute left-[56.9%] -translate-x-[60%] w-[64vw] max1078:-left-[50.9%] borderbox-hidden bottom-[-39px] size1" alt="" />
</div>
);
};

export default Header2;
