// import React from "react";
// import Card from "react-bootstrap/Card";
// import "../Components/comp/Instructor.css";
// // import Auroshikha from "../Utils/images/Instructor/Auroshikha.jpg";
// // import Prajakta from "../Utils/images/Instructor/Prajakta.jpg";
// import NavBar from "../Components/NavBar";
// import Footer from "../Components/Home/Footer";
// import instructor from "../Utils/images/raw/instructor.jpg";
// import pappu from "../Utils/images/Instructor/02.jpg";
// import Rabindra from "../Utils/images/Instructor/01.jpeg";

// const instructorContainerStyle = {
//   width: "100%",
//   marginTop: "1.5rem",
//   paddingBottom: "3rem",
//   borderBottom: "2px solid #2C73EB",
//   backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.936), rgba(0, 0, 0, 0.36)), url(${instructor})`,
//   height: "100%",
// };

// const instructorContainerMobileStyle = {
//   height: "80rem",
// };
// const Instructor = () => {
//   return (
//     <div>
//       <NavBar />
//       <div className="flex flex-col items-center pt-[3rem]"
//       style={
//         window.innerWidth <= 600
//           ? { ...instructorContainerStyle, ...instructorContainerMobileStyle }
//           : instructorContainerStyle
//       }>
//         <div className="grid grid-cols-1 gap-6 md:grid-cols-3 justify-center items-center bg ">
//           <div className="inst-card">
//             <Card
//               className="Box"
//               style={{
//                 backgroundImage: `url(${pappu})`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//                 height: "25rem",
//                 borderRadius: "10px",
//                 margin: "",
//                 width: "19rem",
//               }}
//             >
//               <div className="overlay"></div>
//               <div className="instructor-card-text flex flex-col items-center">
//                 <h4 className="text-[1.3rem] font-semibold">Debabrata Pappu</h4>
//                 <h6>Master Instructo</h6>
//               </div>
//             </Card>
//           </div>
//           <div className="inst-card">
//             <Card
//               className="Box"
//               style={{
//                 backgroundImage: `url(${Rabindra})`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//                 height: "25rem",
//                 borderRadius: "10px",
//                 margin: "29px 0px 0px 426px",
//                 width: "19rem",
//               }}
//             >
//               <div className="overlay"></div>
//               <div className="instructor-card-text flex flex-col items-center">
//                 <h4 className="text-[1.3rem] font-semibold">Rabindra Kumar Sahu</h4>
//                 <h6>Pro Instructor</h6>
//               </div>
//             </Card>
//           </div>
//           {/* <div className="inst-card">
//             <Card
//               className="Box"
//               style={{
//                 backgroundImage: `url(${Roma})`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//                 height: "29rem",
//                 borderRadius: "10px",
//               }}
//             >
//               <div className="overlay"></div>
//               <div className="instructor-card-text flex flex-col items-center">
//                 <h4 className="text-[1.3rem] font-semibold">Roma</h4>
//                 <h6>Master Instructo</h6>
//               </div>
//             </Card>
//           </div> */}
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default Instructor;


// import React from "react";
// import Card from "react-bootstrap/Card";
// import "../Components/comp/Instructor.css";
// import Debabrata from "../Utils/images/Instructor/02.jpg";
// import Rabindra from "../Utils/images/Instructor/01.jpeg";
// import NavBar from "../Components/NavBar";
// import Footer from "../Components/Home/Footer";

// const Instructor = () => {
//   return (
//     <div>
//       <NavBar />
//       <div className="flex flex-col items-center pt-[3rem]">
//         <div className="grid grid-cols-1 gap-6 md:grid-cols-3 justify-center bg ">
//           <div className="inst-card">
//             <Card
//               className="Box"
//               style={{
//                 backgroundImage: `url(${Debabrata})`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//                 height: "29rem",
//                 borderRadius: "10px",
//               }}
//             >
//               <div className="overlay"></div>
//               <div className="instructor-card-text flex flex-col items-center">
//                 <h4 className="text-[1.3rem] font-semibold">Debabrata Pappu</h4>
//                 <h6>Master Instructo</h6>
//               </div>
//             </Card>
//           </div>
//           <div className="inst-card">
//             <Card
//               className="Box"
//               style={{
//                 backgroundImage: `url(${Rabindra})`,
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//                 height: "29rem",
//                 borderRadius: "10px",
//               }}
//             >
//               <div className="overlay"></div>
//               <div className="instructor-card-text flex flex-col items-center">
//                 <h4 className="text-[1.3rem] font-semibold">Rabindra Kumar Sahu</h4>
//                 <h6>Roma</h6>
//               </div>
//             </Card>
//           </div>

//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default Instructor;
import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Home/Footer";
import pappu from "../Utils/images/Instructor/02.jpg";
import Rabindra from "../Utils/images/Instructor/01.jpeg";
import instructor from "../Utils/images/raw/instructor.jpg";

const Instructor = () => {
useEffect(() => {
window.scrollTo(0, 0);
}, []);

// Array of instructor information
const instructors = [
{
name: "Debabrata Pappu",
image: pappu,
position: "Master Instructor",
},
{
name: "Rabindra Kumar Sahu",
image: Rabindra,
position: "Pro Instructor",
},
];

const instructorContainerStyle = {
width: "100%",
marginTop: "1.5rem",
paddingBottom: "3rem",
borderBottom: "2px solid #2C73EB",
backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.936), rgba(0, 0, 0, 0.36)), url(${instructor})`,
height: "100%",
};

const instructorContainerMobileStyle = {
height: "80rem",
};

const rowStyle = {
marginTop: "2rem",
};

// const instructorHeadlineStyle = {
//   textAlign: "center",
//   backgroundColor: "#2C73EB",
//   color: "#ffffff",
//   padding: "8px 20px",
//   boxShadow: "2px 2px #3b393998",
//   cursor: "pointer",
// };

const instCardStyle = {
marginBottom: "5rem",
zIndex: "10",
display: "grid",
alignItems: "center",
justifyContent: "center",
};

const instructorCardTextStyle = {
position: "absolute",
zIndex: "10",
width: "100%",
height: "9rem",
bottom: "0",
paddingTop: "4rem",
color: "#ffff",
background:
"linear-gradient(180deg, #ffffff00 0%, #2C73EB 100%)",
};

const boxStyle = {
boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.342)",
width: "18rem",
height: "24rem",
backgroundSize: "cover",
backgroundRepeat: "no-repeat",
position: "relative",
zIndex: "-20",
backgroundPosition: "center center",
};

// const boxMobileStyle = {
//   width: "17rem",
//   height: "23rem",
// };

// const boxLandscapeStyle = {
//   width: "21rem",
//   height: "29rem",
// };

return (
<>
<div className="flex flex-col items-center w-full h-[100%]">
<NavBar />
<div
className="flex-col items-center instructor-contanier w-full h-screen"
style={
window.innerWidth <= 600
? { ...instructorContainerStyle, ...instructorContainerMobileStyle }
: instructorContainerStyle
}
>
<div
className="flex flex-wrap gap-10 ml-6 row justify-center h-full"
style={rowStyle}
>
{instructors.map((instructor, index) => (
<div
className="inst-card col-xs-3 col-lg-3 col-md-12"
key={index}
style={instCardStyle}
>
<Card
className="Box"
style={{
...boxStyle,
backgroundImage: `url(${instructor.image})`,
position: "relative",
zIndex: -2,
}}
>
<div className="overlay"></div>
<div
className="instructor-card-text flex flex-col items-center"
style={instructorCardTextStyle}
>
<h4 className="text-[1.3rem] font-semibold">
{instructor.name}
</h4>
<h6>{instructor.position}</h6>
</div>
</Card>
</div>
))}
</div>
</div>
</div>
<Footer />
</>
);
};

export default Instructor;

